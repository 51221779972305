import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getAccountInfo, logoutUser, getFreshAccountInfo, setUserProducts } from 'redux/actions/auth';
import { selectUserInfo } from 'redux/selectors/auth';
import { selectCenters } from 'redux/selectors/centers';

const mapStateToProps = (state) => ({
  userInfo: selectUserInfo(state),
  centers: selectCenters(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    getAccountInfo,
    logoutUser,
    getFreshAccountInfo,
    setUserProducts,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
