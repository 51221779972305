import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';

import CaliceoButton from 'components/buttons/CaliceoButton';

import useWindowWidth from 'hooks/use-window-width';

import API from 'api';
import qs from 'qs';
import {notification } from 'antd';
// import './simplePushBlock.scss';

const SubToNewsletter = (props) => {
  const { selectedCenter } = props;

  // const blockData = simplePushBlockRes.data?.blockContentById;

  const [windowWidth, setWindowWidth] = useState(0);

  useWindowWidth({ setWindowWidth });

  const handleSubmitNewsletterForm = (e) => {
    e.preventDefault();
    console.log(document.getElementById("footer-newsletter--input").value);
    const formValue = document.getElementById("footer-newsletter--input").value;
    var formIsValid = true;

    const valuesToSubmit = {
      email: formValue,
      fid: 'newsletter',
      centre:window.location.hostname.split(".")[0],
    };

    var formData = new FormData();
    formData.append("data", qs.stringify(valuesToSubmit));
  
    if (!formValue) {
      formIsValid = false;
    }

    // console.log(formIsValid);
    if (typeof formValue !== "undefined") {
      var pattern = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
      if (!pattern.test(formValue)) {
        formIsValid = false;
      }
    } else {
      formIsValid = false;
    }
    if (formIsValid) {
      
      API.post('/pwd', formData, {headers: {'Content-Type': 'multipart/form-data'}}).then((res) => {
        if (res.data.status === 1) {
          notification.success({
            message: 'Votre inscription a bien été enregistrée.',
          });
        }else {
          if(res.data.status > 1) {
            if(res.data.errorMessage != "" && res.data.errorMessage != undefined) {
              notification.error({
                message: res.data.errorMessage,
              });
            }
          }
        }
      });
      
    }else {
      notification.error({
        message: "Adresse mail incorrecte",
      });
    }       
  };

  return (
    <div className="simple-push-block--wrapper sub-newsletter-wrapper">
      <div className="container">
        <div className="simple-push-block--body">
          <div className="simple-push-block--title newsletter-sub">
            <h1>
              Vous souhaitez recevoir
              nos offres personnalisées ?
            </h1>
            <p>
              Inscrivez-vous à notre newsletter !
            </p>
          </div>
          <div className="promo-section-input--wrapper">
            <input id="footer-newsletter--input" className="promo-section-input" type="text" />
            <CaliceoButton
              title=""
              primaryButton
              onClick={handleSubmitNewsletterForm}
              classNames="confirm-promo--btn"
              showIcon
            />
          </div>
          {/* <CaliceoLink
            iconFill="#8bacdb"
            title={blockData.fieldLien.title}
            url={blockData.fieldLien.url.path}
            primaryButton={false}
            classNames="simple-push-block--link primary-caliceo--button--inverted"
          /> */}
        </div>
      </div>
    </div>
  );
};

export default SubToNewsletter;
