import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';

import { setUserLoggedIn, getUserInfo } from 'redux/actions/auth';
import { selectCenters } from 'redux/selectors/centers';
import { selectUserInfo } from 'redux/selectors/auth';

const mapStateToProps = (state) => ({
  centers: selectCenters(state),
  userInfo: selectUserInfo(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    setUserLoggedIn,
    getUserInfo,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
