import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import userIsLoggedIn from 'utils/checkLoggedIn';
import Layout from 'components/layout';
import { getCenterById } from 'utils/getCentersBy';
import { getCenterName } from 'utils/location';
import useSetCenterCookie from 'hooks/use-set-center-cookie';
import { centerCookieIds } from 'utils/constants';
import ModifyAccountForm from './ModifyAccountForm';
import 'components/register/register.scss';

const ModifyAccount = (props) => {
  const { centers, actions, location, userInfo } = props;
  const [centerObj, setCenterObj] = useState(null);

  const centerName = getCenterName(props.location);

  useEffect(() => {
    setCenterObj(getCenterById(centers, `centre-${centerName}`));
  }, [centers, centerName]);

  useSetCenterCookie(`centre-${centerName}`);

  useEffect(() => {
    actions.getUserInfo(
      localStorage.getItem(centerCookieIds[`centre-${centerName}`].cookieName),
      `centre-${centerName}`,
    );
  }, []);

  useEffect(() => {
    if (!userIsLoggedIn(`centre-${centerName}`)) {
      navigate('/login');
    }
  }, [centerName]);

  return (
    <Layout location={location}>

      <div>
        <div className="register-page--header container mt-5">
          {centerObj && (
            <h3>{centerObj?.name}</h3>
          )}
          <h1>Entrez dans la bulle Calicéo</h1>
        </div>
        <ModifyAccountForm
          userInfo={userInfo}
          location={location}
          setUserLoggedIn={actions.setUserLoggedIn}
          selectedCenter={centerObj}
        />
      </div>
    </Layout>
  );
};

export default ModifyAccount;
