import React from 'react';

import CaliceoButton from 'components/buttons/CaliceoButton';
import { sixthImage } from 'utils/dummyImages';
import { numberDisplayer } from 'utils/number';

const OrderDetailsBody = (props) => {
  const {
    order,
    selectedCenter,
    orderTicket,
    orderInvoice,
    orderState,
    boncadeauType
  } = props;
  //console.log(boncadeauType);
  const openTicket = (ticket) => {
    const file = new Blob(
      [ticket],
      { type: 'application/pdf' },
    );
    const fileURL = URL.createObjectURL(file);

    if (typeof window !== 'undefined') {
      window.open(fileURL);
    }
  };
  // console.log('order.lineItems', order.lineItems);
  // console.log('order', order);
  // console.log('orderState', orderState);
  var printTicketButton;
  if ((orderState?.includes('valable') || orderState?.includes('planifié le')) && (boncadeauType != "S")) {
    printTicketButton = <CaliceoButton
      title="Imprimer mon e-billet"
      // onClick={() => window.open(orderTicket, '_blank')}
      onClick={() => openTicket(orderTicket)}
      primaryButton
      classNames="mr-3"
    />;
  }
  return (
    <div className="container">
      {order.invoiceNumber && (
        <h4 className="order-number">
          &#8470; de facture: {order.invoiceNumber}
        </h4>
      )}
      {order.orderNumber && (
        <h4 className="order-number">
          &#8470; de commande: {order.orderNumber}
        </h4>
      )}
      {/* <h4 className="order-number">
        &#8470; commande: {order.orderNumber}
      </h4> */}
      <div className="order-products--wrapper">
        {order.lineItems.map((lineItem) => (
          <div key={`${lineItem.title}-${lineItem.montantTVA}-${lineItem.quantity}`} className="order-product">
            <div className="order-product-body">
              <div className="order-product-image--wrapper">
                <img src={lineItem.imgUrl || sixthImage} alt={lineItem.title} className="order-product-image" />
              </div>
              <div className="order-product-info">
                <h3 className="order-product-title">{lineItem.title} x{lineItem.quantity}</h3>
              </div>
              <p className="cart-product-price ml-auto">{`${lineItem.montantTTC}€`}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="order-products-button--wrapper">
        {printTicketButton}
        <CaliceoButton
          title="Imprimer ma facture"
          onClick={() => openTicket(orderInvoice)}
          primaryButton
        />
      </div>
      <div className="order-prices">
        <div className="order-prices--container">
          <div className="order-price-item main-price-item">
            <p className="order-price-title">
              Total TTC :
            </p>
            <p className="order-price--price">
              {numberDisplayer(order.totalPrice)}€
            </p>
          </div>
          <div className="order-price-item">
            <p className="order-price-title">
              Dont TVA :
            </p>
            <p className="order-price--price">
              {numberDisplayer(order.tax)}€
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailsBody;
