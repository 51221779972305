import React from 'react';
import { Link } from 'gatsby';

const OrderDetailsBreadcrumb = (props) => {
  const { selectedCenter, order } = props;

  return (
    <div className="offers-header--section container">
      <div className="offers-header--wrapper my-account-header--wrapper">
        <div className="offers-header--breadcrumbs--wrapper">
          <Link className="offers-link" to="/">{selectedCenter?.name}</Link>
          {'  >  '}
          <Link className="offers-link active-offer-link" to="/mon-compte">
            Mon espace
          </Link>
          {'  >  '}
          <Link className="offers-link active-offer-link" to={`/mon-compte/command/${order?.orderNumber}`}>
            Commande {order?.orderNumber}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailsBreadcrumb;
