import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { Skeleton } from 'antd';
import { useParams } from '@reach/router';

import Layout from 'components/layout';
import SEO from 'components/seo'

//import SubToNewsletter from 'components/my-account/SubToNewsletter';
import Reinsurance from 'components/home-group/reinsurance/Reinsurance';

import API from 'api';
import { centerCookieIds } from 'utils/constants';
import { getCenterById } from 'utils/getCentersBy';

import OrderDetailsBreadcrumb from './OrderDetailsBreadcrumb';
import OrderDetailsBody from './OrderDetailsBody';

import load_gif from '../../images/load.gif';

const cookies = new Cookies();

import './orderDetails.scss';

const OrderDetails = (props) => {
  const { location, centerId, centers } = props;

  const params = useParams();
  const [selectedCenter, setSelectedCenter] = useState(null);
  const [order, setOrder] = useState(null);
  const [orderInvoice, setOrderInvoice] = useState('');
  const [orderTicket, setOrderTicket] = useState('');
  //console.log('location', location);
  useEffect(() => {
    API.get(`/my-account/order-details?session=${cookies.get(centerCookieIds[centerId].cookieName)}&id=${params.orderId}`, { centerId }).then((res) => {
      if (res.data.status === 1) {
        setOrder(res.data.responseData.accountContent);
      } else {
        setOrder(null);
      }
    });
    API.get(`/my-account/order-details/invoice?session=${cookies.get(centerCookieIds[centerId].cookieName)}&id=${params.orderId}`, { responseType: 'blob', centerId }).then((res) => {
      setOrderInvoice(res.data);
    });
    API.get(`/my-account/order-details/ticket?session=${cookies.get(centerCookieIds[centerId].cookieName)}&id=${location?.state?.lid}&pt=${location?.state?.pt}`, { responseType: 'blob', centerId }).then((res) => {
      setOrderTicket(res.data);
    });
  }, []);

  useEffect(() => {
    setSelectedCenter(getCenterById(centers, centerId));
  }, [centerId, centers]);

  return (
    <Layout location={location}>
      <SEO title="Order details" />
      {order ? (
        <>
          <OrderDetailsBreadcrumb order={order} selectedCenter={selectedCenter} />
          <OrderDetailsBody
            orderTicket={orderTicket}
            orderInvoice={orderInvoice}
            order={order}
            selectedCenter={selectedCenter}
            orderState={location?.state?.order_state}
            boncadeauType={location?.state?.bt}
          />
        </>
      ) : (
        <div className="container">
          <div className={"loader "}>
            <img src={load_gif} style={{width:50}} />
          </div>
        </div>
      )}
      {/* <SubToNewsletter centerId={centerId} /> */}
      <Reinsurance location={location} />
    </Layout>
  );
};

export default OrderDetails;
