import React from 'react';

import Layout from 'components/layout';
import SEO from 'components/seo';
import Reinsurance from 'components/home-group/reinsurance/Reinsurance';
import MyAccount from 'components/my-account';
import SubToNewsletter from 'components/my-account/SubToNewsletter';

const MyAccountPage = (props) => {
  const { location, centerId } = props;
  return (
    <Layout location={location}>
      <SEO title="Mon compte" />
      <MyAccount centerId={centerId} />
      <SubToNewsletter centerId={centerId} />
      <Reinsurance location={location} />
    </Layout>
  );
};

export default MyAccountPage;
