import React, { useState, useEffect } from 'react';
import { Router } from '@reach/router'

import MyAccountPage from 'components/my-account/MyAccountPage';

import ModifyAccount from 'components/my-account/ModifyAccount';

import OrderDetails from 'components/order-details';
import useSetCenterCookie from 'hooks/use-set-center-cookie';
import { getCenterName } from 'utils/location';

const MonCompte = (props) => {
  const [centerId, setCenterId] = useState('');
  const centerName = getCenterName(props.location);

  useEffect(() => {
    setCenterId(`centre-${centerName}`);
  }, [centerName]);

  useSetCenterCookie(centerId);

  if (!centerId) {
    return null;
  }

  return (
    <Router path="/">
      <MyAccountPage centerId={centerId} path="/mon-compte" />
      <OrderDetails centerId={centerId} path="/mon-compte/commande/:orderId" />
      <ModifyAccount centerId={centerId} path="/mon-compte/modifier" />
    </Router>
  );
};

export default MonCompte;
