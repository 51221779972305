import React from 'react';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';

const iconStyle = {
  fontSize: 16,
  marginRight: 5,
  cursor: 'pointer',
  marginLeft: 10,
  verticalAlign: 'text-bottom',
  lineHeight: 1,
};

const ExpandIcon = (props) => (
  <span role="presentation" style={iconStyle} onClick={props.onClick}>
    {props.expanded ? (
      <MinusCircleOutlined />
    ) : (
      <PlusCircleOutlined />
    )}
  </span>
);

export default ExpandIcon;
