import React, { useState, useEffect } from "react"
import { Table, Divider, notification, Collapse } from "antd"
import { navigate, Link } from "gatsby"
import "moment/locale/fr"
import API from "api"
import qs from "qs"
import Cookies from "universal-cookie"
import { centerCookieIds } from "utils/constants"
import userIsLoggedIn from "utils/checkLoggedIn"
import moment from "moment"
import RightArrow from "components/icon-components/RightArrow"
import CaliceoButton from "components/buttons/CaliceoButton"
import CaliceoLink from "components/buttons/CaliceoLink"
import capitalizeFirstLetter from "utils/capitalizeFirstLetter"
import QrCodeIcon from "images/icons/qr-code.svg"
import PersonIcon from "images/icons/person-icon.svg"

import BalneoBooking from "components/balneo-booking/BalneoBooking"
import CancellationModal from "components/cancellation-modal/CancellationModal"
import RGPDModal from "components/modals/RGPDModal"
import CancellationModal2 from "components/cancellation-modal/CancellationModal2"
import SpaBookingModal from "components/spa-booking/SpaBookingModal"
import BookingCompletedModal from "components/booking-completed/BookingCompletedModal"
import SpaBookingFirstStep from "components/spa-booking/SpaBookingFirstStep"
import ExpandIcon from "./ExpandTableIcon/ExpandTableIcon"
import MyAccountHeader from "./MyAccountHeader"
import NoBookingModal from "components/cancellation-modal/NoBookingModal"
import "./myAccount.scss"

import load_gif from "../../images/load.gif"

const cookies = new Cookies()

const MyAccount = props => {
  const { actions, userInfo, centers, centerId } = props

  const { infoCard } = userInfo

  //console.log(userInfo);

  const [showBookingModal, setShowBookingModal] = useState(false)
  const [showRGPDModal, setShowRGPDModal] = useState(false)
  const [bookingData, setBookingData] = useState({})
  const [loadingBookingData, setLoadingBookingData] = useState(false)
  const [spaBookingData, setSpaBookingData] = useState([])
  const [showSpaBookingModal, setShowSpaBookingModal] = useState(false)
  const [showBookingCompleted, setShowBookingCompleted] = useState(false)
  const [selectedOffer, setSelectedOffer] = useState(null)
  const [showFirstStepBooking, setShowFirstStepBooking] = useState(false)
  const [goToNextStepBooking, setGoToNextStepBooking] = useState(false)
  const [activeVariant, setActiveVariant] = useState(null)
  const [bonCadeauData, setBonCadeauData] = useState(null)
  const [selectedOid, setSelectedOid] = useState(null)
  const [choices, setChoices] = useState(null)
  const [abonid, setAbonid] = useState("a99")
  const [rd, setRs] = useState("a99")
  const [soid, setSoid] = useState("a99")
  const [showCancellationModal, setShowCancellationModal] = useState(false)
  const [showCancellationModal2, setShowCancellationModal2] = useState(false)
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [showLoader, setShowLoader] = useState(false)
  const [rendezvousData, setRendezvousData] = useState([])
  const [showNoBookingModal, setShowNoBookingModal] = useState(false)

  const { Panel } = Collapse

  const [load_1, setLoad_1] = useState(0)

  const handleChangeVariant = val => {
    setActiveVariant(val)
  }

  useEffect(() => {
    const _data = {
      session: cookies.get(centerCookieIds[centerId]?.cookieName),
    }
    const qstring = Object.keys(_data)
      .map(key => `${key}=${_data[key]}`)
      .join("&")
    API.post(`/rendezvous`, qs.stringify(_data), { centerId }).then(res => {
      if (res.data.status === 1) {
        //console.log(res.data.responseData);
        if (res.data.responseData.accountContent.rendezvous.length > 0) {
          //const rdvAff = res.data.responseData.accountContent.rendezvous.filter((rdv) => rdv.rendezvousPaye !== false)
          setRendezvousData(res.data.responseData.accountContent.rendezvous)
        }
      } else {
        if (res.data.status > 1) {
          if (
            res.data.errorMessage != "" &&
            res.data.errorMessage != undefined
          ) {
            notification.error({
              message: res.data.errorMessage,
            })
          }
        }
      }
    })
  }, [])

  const openBookingModal = _record => {
    setSelectedOffer(_record)

    //const tommorow = moment().add(1, 'days').format('DD/MM/YYYY');
    const today = moment().format("DD/MM/YYYY")
    const _bookingData = {
      session: cookies.get(centerCookieIds[centerId]?.cookieName),
      bid: "b",
      cid: centerCookieIds[centerId]?.cid,
      pid: _record.pid,
      tid: _record.pid,
      date: today,
    }
    const qstring = Object.keys(_bookingData)
      .map(key => `${key}=${_bookingData[key]}`)
      .join("&")

    setLoadingBookingData(true)
    API.get(`/fctfbot?${qstring}`, { centerId }).then(res => {
      if (res.data.status === 1) {
        const uniqueRes = res.data.responseData.bookingContent.filter(
          (v, i, a) =>
            a.findIndex(t => JSON.stringify(t) === JSON.stringify(v)) === i
        )
        setBookingData({ data: uniqueRes, selectedProduct: _record })
        setShowBookingModal(true)
      } else {
        if (res.data.status === 0) {
          setShowNoBookingModal(true)
        } else {
          if (res.data.status > 1) {
            if (
              res.data.errorMessage != "" &&
              res.data.errorMessage != undefined
            ) {
              notification.error({
                message: res.data.errorMessage,
              })
            }
          }
        }
      }
      setShowLoader(false)
      setLoadingBookingData(false)
    })
  }

  useEffect(() => {
    const isValidCenter = centers.some(_center =>
      _center.centerId.includes(centerId)
    )
    if (isValidCenter) {
      if (userIsLoggedIn(centerId)) {
        actions.getAccountInfo(
          localStorage.getItem(centerCookieIds[centerId].cookieName),
          centerId
        )
      } else {
        navigate("/login")
      }
    } else {
      navigate("/login")
    }
  }, [centers, centerId, actions])

  useEffect(() => {
    if (Object.entries(userInfo.products).length > 0) {
      setLoad_1(1)
    }
    //console.log(userInfo);
    if (userInfo?.rgpd_data === null) {
      setShowRGPDModal(true)
    }
  }, [userInfo])

  //console.log(userInfo?.products);

  const selectOffer = _offer => {
    setShowLoader(true)
    //console.log("????????????");
    //console.log(_offer);
    ////console.log(bonCadeauData);
    setSelectedOffer(_offer)
    const tommorow = moment().add(1, "days").format("DD/MM/YYYY")
    const today = moment().format("DD/MM/YYYY")
    const spaData = {
      session: cookies.get(centerCookieIds[centerId]?.cookieName),
      bid: "s",
      cid: centerCookieIds[centerId]?.cid,
      pid: _offer.pid,
      tid: 1,
      date: tommorow,
      //date:today
    }
    const qstring = Object.keys(spaData)
      .map(key => `${key}=${spaData[key]}`)
      .join("&")
    return API.get(`/fctfbot?${qstring}`, { centerId }).then(res => {
      setShowLoader(false)
      //console.log(res.data.responseData);
      if (res.data.status === 1) {
        //console.log(res.data.responseData.bookingContent);
        setSpaBookingData(res.data.responseData.bookingContent)
        setShowSpaBookingModal(true)
      } else {
        if (res.data.status === 0) {
          setShowNoBookingModal(true)
        } else {
          if (res.data.status > 1) {
            if (
              res.data.errorMessage != "" &&
              res.data.errorMessage != undefined
            ) {
              notification.error({
                message: res.data.errorMessage,
              })
            }
          }
        }
      }
    })
  }

  const openRegularOffer = offer => {
    //console.log(offer);
    if (offer.bt === "B") {
      openBookingModal(offer)
    } else {
      selectOffer(offer)
    }
  }

  const getBonCadeau = _offer => {
    //console.log(_offer);
    if (_offer?.abonnement?.choices) {
      if (_offer?.abonnement?.choices.length === 1) {
        // cas d'un abonnement où il faut passer la première popup (choix du soins)
        setShowLoader(true)
        setSelectedOffer(_offer)
        const offerData = {
          session: cookies.get(centerCookieIds[centerId]?.cookieName),
          cid: centerCookieIds[centerId]?.cid,
          oid: _offer.lid,
          pt: _offer.pt,
        }
        console.log("1")
        const qstring = Object.keys(offerData)
          .map(key => `${key}=${offerData[key]}`)
          .join("&")
        return API.get(`/get-bon-cadeau-by-oid?${qstring}`, { centerId }).then(
          res => {
            if (res.data.status === 1) {
              console.log("2")
              setBonCadeauData(res.data.responseData.bonCadeau)
              setAbonid(_offer?.abonnement?.choices[0].abonid)
              setSelectedOid(_offer?.abonnement?.choices[0].id)

              const offerData = {
                ..._offer,
                pid: _offer?.abonnement?.choices[0].id,
              }
              openRegularOffer(offerData)
            } else {
              console.log("3")
              setShowLoader(false)
              if (res.data.status > 1) {
                if (
                  res.data.errorMessage != "" &&
                  res.data.errorMessage != undefined
                ) {
                  notification.error({
                    message: res.data.errorMessage,
                  })
                }
              }
            }
          }
        )
      } else {
        setShowLoader(true)
        setSelectedOffer(_offer)
        const offerData = {
          session: cookies.get(centerCookieIds[centerId]?.cookieName),
          cid: centerCookieIds[centerId]?.cid,
          oid: _offer.lid,
          pt: _offer.pt,
        }
        console.log("5")
        const qstring = Object.keys(offerData)
          .map(key => `${key}=${offerData[key]}`)
          .join("&")
        return API.get(`/get-bon-cadeau-by-oid?${qstring}`, { centerId }).then(
          res => {
            if (res.data.status === 1) {
              console.log("6")
              setShowLoader(false)
              setBonCadeauData(res.data.responseData.bonCadeau)

              if (
                res.data.responseData.bonCadeau.consommables.length <= 1 &&
                !res.data.responseData.bonCadeau.consommables[0].isChoice
              ) {
                console.log("7")
                openRegularOffer(_offer)
              } else {
                console.log("8")
                setShowFirstStepBooking(true)
              }
            } else {
              console.log("9")
              setShowLoader(false)
              if (res.data.status > 1) {
                if (
                  res.data.errorMessage != "" &&
                  res.data.errorMessage != undefined
                ) {
                  //console.log("10");
                  notification.error({
                    message: res.data.errorMessage,
                  })
                }
              }
            }
          }
        )
      }
    } else {
      setShowLoader(true)
      setSelectedOffer(_offer)
      const offerData = {
        session: cookies.get(centerCookieIds[centerId]?.cookieName),
        cid: centerCookieIds[centerId]?.cid,
        oid: _offer.lid,
        pt: _offer.pt,
      }
      //console.log(_offer);
      console.log("11")
      if (_offer.lid != "a99") {
        console.log("12")
        const qstring = Object.keys(offerData)
          .map(key => `${key}=${offerData[key]}`)
          .join("&")
        return API.get(`/get-bon-cadeau-by-oid?${qstring}`, { centerId }).then(
          res => {
            if (res.data.status === 1) {
              //console.log(res.data.responseData)
              setShowLoader(false)
              setBonCadeauData(res.data.responseData?.bonCadeau)
              //openRegularOffer(_offer);
              //console.log(res.data.responseData?.bonCadeau);
              if (
                res.data.responseData.bonCadeau.consommables.length <= 1 &&
                !res.data.responseData.bonCadeau.consommables[0].isChoice
              ) {
                console.log("15")
                setSelectedOid(res.data.responseData?.bonCadeau.consommables[0].elements[0].id);
                openRegularOffer(_offer)
              } else {
                console.log("16")
                //console.log(_offer);
                if (
                  _offer.pid === 133 ||
                  _offer.pid === 754 ||
                  _offer.pid === 753 ||
                  _offer.pid === 18 ||
                  _offer.pid === 626 ||
                  _offer.pid === 625 ||
                  _offer.pid === 624 ||
                  _offer.pid === 693 ||
                  _offer.pid === 694 ||
                  _offer.pid === 695 ||
                  _offer.pid === 139 ||
                  _offer.pid === 137 ||
                  _offer.pid === 139 ||
                  _offer.pid === 140 ||
                  _offer.pid === 138
                ) {
                  openRegularOffer(_offer)
                } else {
                  setShowFirstStepBooking(true)
                }
              }
            } else {
              console.log("17")
              setShowLoader(false)
              if (res.data.status > 1) {
                if (
                  res.data.errorMessage != "" &&
                  res.data.errorMessage != undefined
                ) {
                  notification.error({
                    message: res.data.errorMessage,
                  })
                }
              }
            }
          }
        )
        console.log("13")
        offerData.bt = "B"
        offerData.pid = "a99"
        openRegularOffer(offerData)
        setShowLoader(false)
      } else {
        console.log("13")
        offerData.bt = "B"
        offerData.pid = "a99"
        openRegularOffer(offerData)
        setShowLoader(false)
      }
    }
  }

  const renderActionButton = offer => {
    if (offer?.abonnement?.remaining > 0) {
      return (
        <CaliceoButton
          primaryButton
          showIcon={false}
          classNames="view-product-column product-action-column"
          onClick={() => {
            if (offer.pt === "bc" || offer.pt === "ab") {
              getBonCadeau(offer)
            } else {
              openRegularOffer(offer)
            }
          }}
          title="PLANIFIER"
        />
      )
    }
    if (offer?.state.includes("planifier")) {
      // Massage
      if (
        offer.pid != 743 &&
        offer.pid != 744 &&
        offer.pid != 406 &&
        offer.pid != 422
      ) {
        return (
          <CaliceoButton
            primaryButton
            showIcon={false}
            classNames="view-product-column product-action-column"
            onClick={() => {
              //console.log(offer);
              if (offer.pt === "bc" || offer.pt === "ab") {
                getBonCadeau(offer)
              } else {
                openRegularOffer(offer)
              }
            }}
            title="PLANIFIER"
          />
        )
      } else {
        return null
      }
    }
    //console.log(offer);
    if (offer?.state.includes("planifié")) {
      // ANNULER UN SOIN SPA (MASSAGE,...) ==> POPUP avec tel
      return (
        <CaliceoButton
          primaryButton
          showIcon={false}
          classNames="view-product-column product-action-column"
          onClick={() => setShowCancellationModal(true)}
          title="ANNULER"
        />
      )
    }
    if (offer?.state.includes("À utiliser ?")) {
      return (
        <></>
        // <CaliceoButton
        //   primaryButton
        //   showIcon={false}
        //   classNames="view-product-column product-action-column logout-button"
        //   onClick={() => {
        //     /*if (offer.pt === 'bc') {
        //       getBonCadeau(offer);
        //     } else {
        //       openRegularOffer(offer);
        //     }*/
        //     ////console.log(offer);
        //     var new_abonid = "a99";

        //     if(offer?.abonnement?.id) {
        //       new_abonid = offer?.abonnement?.id;
        //     }

        //     const _data = {
        //       session: cookies.get(centerCookieIds[centerId]?.cookieName),
        //       pt:offer.pt,
        //       bcid: offer.lid,
        //       cid: centerCookieIds[centerId]?.cid,
        //       pid:offer.pid,
        //       bt:offer.bt,
        //       abonId:new_abonid,
        //     };

        //     const qstring = Object.keys(_data).map((key) => `${key}=${_data[key]}`).join('&');
        //     API.post(`/utilise-boncadeau`, qs.stringify(_data), { centerId }).then((res) => {
        //       if (res.data.status === 1) {
        //         actions.getFreshAccountInfo(
        //           localStorage.getItem(centerCookieIds[centerId].cookieName),
        //           centerCookieIds[centerId]?.cid,
        //         );
        //       }else {
        //         if(res.data.status > 1) {
        //           if(res.data.errorMessage != "" && res.data.errorMessage != undefined) {
        //             notification.error({
        //               message: res.data.errorMessage,
        //             });
        //           }
        //         }
        //       }
        //     });
        //   }}
        //   title="UTILISER MON BON CADEAU"
        // />
      )
    }
    if (offer.name) {
      if (offer.name.includes("RECHARGE")) {
        return null
      }
    }
    return (
      <CaliceoLink
        primaryButton
        classNames="view-product-column product-action-column"
        title="RECOMMANDER"
        showIcon={false}
        url={offer.offerAlias}
      />
    )
  }

  const getEBillet = (_pid, _pt) => {
    API.get(
      `/my-account/order-details/ticket?session=${cookies.get(
        centerCookieIds[centerId].cookieName
      )}&id=${_pid}&pt=${_pt}`,
      { responseType: "blob", centerId }
    ).then(res => {
      const file = new Blob([res.data], { type: "application/pdf" })
      const fileURL = URL.createObjectURL(file)
      window.open(fileURL)
    })
  }

  const isRecordExpanded = (eRows, oid) => eRows.includes(`${oid}`)

  const activeProductColumns = [
    {
      title: "N° de commande",
      dataIndex: "oid",
      sorter: (a, b) => a.oid - b.oid,
    },
    {
      title: "Date d’achat",
      dataIndex: "buyDate",
      sorter: (a, b) => moment(a?.buyDate).unix() - moment(b?.buyDate).unix(),
      render: buyDate => (buyDate ? moment(buyDate).format("DD/MM/YYYY") : "/"),
    },
    {
      title: "Centre",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: record => {
        return (
          <span className="black-status-column column-centre">
            {record?.etablissement?.code}
            {record?.rendezvousEtablissement?.nom}
          </span>
        )
      },
    },
    {
      title: "Produit",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: record => {
        if (record?.abonnement?.productItems?.length > 0) {
          return (
            <span className="name-column">
              {record.name}
              <ExpandIcon
                onClick={() => onExpandIconClick(record.oid + record.pid)}
                expanded={isRecordExpanded(
                  expandedRowKeys,
                  record.oid + record.pid
                )}
              />
            </span>
          )
        }
        return <span className="name-column">{record.name}</span>
      },
    },
    {
      title: "Statut",
      sorter: (a, b) => a.state.localeCompare(b.state),
      render: record => {
        if (record.state.includes("en liste d")) {
          return (
            <span className="orange-status-column">
              {capitalizeFirstLetter(record.state)}
            </span>
          )
        }
        if (record.state.includes("valable")) {
          return (
            <span className="green-status-column">
              {capitalizeFirstLetter(record.state)}
            </span>
          )
        }
        if (record.abonnement?.remaining) {
          return (
            <div className="d-flex flex-column">
              <span className="green-status-column">
                {capitalizeFirstLetter(record.state)}
              </span>
              <span className="green-status-column">
                {capitalizeFirstLetter("Restant :")}{" "}
                {record.abonnement?.remaining}
              </span>
            </div>
          )
        }
        if (record?.state == "À utiliser ?") {
          return <span className="black-status-column"></span>
        }
        return (
          <span className="black-status-column">
            {capitalizeFirstLetter(record.state)}
          </span>
        )
      },
    },
    {
      title: null,
      render: record => {
        if (
          record.pt &&
          record.bt != "S" &&
          (record.state.includes("valable") ||
            record.state.includes("planifié le"))
        ) {
          if (record["e-billet"]) {
            return (
              <button
                className="qr-code-column"
                type="button"
                onClick={() => getEBillet(record.lid, record.pt)}
              >
                <img src={QrCodeIcon} alt="Qr code icon" />
              </button>
            )
          }
        }
        return null
      },
    },
    {
      title: null,
      render: record => {
        if (record?.state !== "À utiliser ?" && !record?.offered) {
          return (
            <button
              type="button"
              className="view-product-column"
              onClick={() =>
                navigate(`/mon-compte/commande/${record.oid}`, {
                  state: {
                    lid: record.lid,
                    pt: record.pt,
                    order_state: record.state,
                    bt: record.bt,
                  },
                })
              }
            >
              VOIR
            </button>
          )
        }
        return null
      },
    },
    {
      title: null,
      render: record => renderActionButton(record),
    },
  ]

  const nestedActiveColumns = [
    {
      title: "N° de commande",
      dataIndex: "title",
      render: () => <span className="invisible">0000000</span>,
    },
    {
      title: "Date d’achat",
      dataIndex: "title",
      render: () => <span className="invisible">01/01/1970</span>,
    },
    {
      title: "Centre",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: record => {
        return (
          <span className="black-status-column column-centre">
            {record?.etablissement?.code}
          </span>
        )
      },
    },
    {
      title: "Produit",
      render: record => <span className="name-column">{record.title}</span>,
    },
    {
      title: "Statut",
      render: record => (
        <span className="black-status-column">Planifié le {record.date}</span>
      ),
    },
    {
      title: null,
      render: record => (
        <button className="qr-code-column invisible" type="button">
          <img src={QrCodeIcon} alt="Qr code icon" />
        </button>
      ),
    },
    {
      title: null,
      render: record => {
        if(!record?.offered) {
          return (
            <button type="button" className="view-product-column invisible" onClick={() =>
              navigate(`/mon-compte/commande/${record.oid}`, {
                state: {
                  lid: record.lid,
                  pt: record.pt,
                  order_state: record.state,
                  bt: record.bt,
                },
              })
            }>
              VOIR
            </button>
          )
        }else {
          return null;
        }
        },
    },
    {
      title: null,
      render: () => (
        <CaliceoButton
          primaryButton
          showIcon={false}
          classNames="view-product-column product-action-column"
          onClick={() => setShowCancellationModal(true)}
          title="ANNULER"
        />
      ),
    },
  ]

  const NestedTable = record => (
    <Table
      size="middle"
      bordered={false}
      columns={nestedActiveColumns}
      dataSource={record.record.abonnement.productItems}
      showHeader={false}
      pagination={false}
      rowKey={_record => _record.oid}
    />
  )

  const activeRendezVousColumns = [
    {
      title: "Nom du rendez-vous",
      dataIndex: "rendezvousName",
      sorter: (a, b) => a.rendezvousName.localeCompare(b.rendezvousName),
    },
    {
      title: "Date",
      sorter: (a, b) => {
        return a.rendezvousDate.localeCompare(b.rendezvousDate)
      },
      render: record => {
        return "Le " + record.rendezvousDate + " à " + record.rendezvousHour
      },
    },
    {
      title: "Centre",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: record => {
        return (
          <span className="black-status-column column-centre">
            {record?.etablissement?.code}
            {record?.rendezvousEtablissement?.nom}
          </span>
        )
      },
    },
    {
      title: "Statut",
      sorter: (a, b) => a.rendezvousType.localeCompare(b.rendezvousType),
      render: record => {
        if (record.rendezvousType === "at") {
          return (
            <span className="orange-status-column">En liste d'attente</span>
          )
        } else if (record.rendezvousType === "rv") {
          return <span className="green-status-column">Confirmé</span>
        } else {
          return null
        }
      },
    },
    {
      title: null,
      render: record => {
        //console.log(record);
        if (record.rendezvousCancellable === true) {
          return (
            <CaliceoButton
              primaryButton
              showIcon={false}
              classNames="view-product-column product-action-column"
              onClick={() => setShowCancellationModal2(true)}
              title="ANNULER"
            />
          )
        }else {
            return null
          
        }
      },
    },
  ]

  const expandedRowRenderer = record => <NestedTable record={record} />

  const toggleExpandByCaseId = oid => {
    const exists = expandedRowKeys.includes(`${oid}`)
    if (exists) {
      const newRowKeys = expandedRowKeys.filter(rowKey => rowKey !== `${oid}`)
      setExpandedRowKeys(newRowKeys)
    } else {
      const newRowKeys = [...expandedRowKeys, `${oid}`]
      setExpandedRowKeys(newRowKeys)
    }
  }

  const onExpandIconClick = oid => {
    toggleExpandByCaseId(oid)
  }

  const onExpand = (expanded, record) => {
    toggleExpandByCaseId(record.oid + record.pid)
  }

  let vraiRdv = []
  if (userInfo?.products.length != 0) {
    vraiRdv = userInfo?.products?.available?.filter(
      rdv => rdv.state !== "en cours"
    )
  } else {
    vraiRdv = userInfo?.products?.available
  }

  const sortedActiveProducts = vraiRdv?.sort((a, b) =>
    b?.buyDate?.localeCompare(a.buyDate)
  )

  const sortedExpiredProducts = userInfo?.products?.expired?.sort((a, b) =>
    b?.buyDate?.localeCompare(a.buyDate)
  )

  return (
    <>
      <div className="my-account-page">
        <div className="container">
          <MyAccountHeader centerId={centerId} centers={centers} />
          <div className="my-account-details--wrapper">
            <div className="my-account-details">
              {userInfo?.firstname && userInfo?.lastname ? (
                <h1 className="my-account-title">
                  Bienvenue
                  {/* {' '} */}
                  {/* <span className="">{`${userInfo?.firstname} ${userInfo?.lastname}`}</span> */}
                  <span className="">{` ${userInfo?.firstname}`}</span>
                </h1>
              ) : null}
              <p className="my-account-subtitle">
                Vous êtes actuellement sur votre compte personnel Calicéo.
              </p>
              <p className="my-account-subtitle">
                Vous pouvez maintenant suivre l’état de vos réservations et
                modifier vos informations
              </p>
              <div className="my-account-modify">
                <h3>Mon compte</h3>
                <Link className="modify-link" to="/mon-compte/modifier">
                  Modifier mes informations personnelles{" "}
                  <RightArrow fill="#414141" />
                </Link>
                <Divider />
                <Link className="modify-link" to="/reset-password">
                  Modifier mon mot de passe <RightArrow fill="#414141" />
                </Link>
              </div>
            </div>
            <div className="my-account-card">
              <img
                className="account-card-icon"
                src={PersonIcon}
                alt="person icon"
              />
              <p className="account-card-today">aujourd'hui,</p>
              <p className="account-card-today">
                le {moment().format("DD/MM/YYYY")}
              </p>

              {infoCard?.tempo?.date ? (
                <div className="nouv_zone">
                  <p className="account-card-info">
                    Il vous reste{" "}
                    <span className="card-info-value">
                      {infoCard?.tempo?.timeLeft?.hours}H{" "}
                      {infoCard?.tempo?.timeLeft?.minutes}m
                    </span>{" "}
                    de votre carte Tempo
                    {infoCard?.tempo?.date ? (
                      <>
                        {" "}
                        jusqu’au{" "}
                        {moment(infoCard?.tempo?.date)
                          .locale("fr")
                          .format("DD/MM/YYYY")}
                      </>
                    ) : null}
                  </p>
                  {infoCard?.urlAlias?.tempo ? (
                    <div className="cont_new_btn">
                      <button
                        type="button"
                        className="primary-caliceo--button "
                        onClick={() => {
                          //navigate("/produit" + infoCard.urlAlias.tempo);
                          navigate("/offers/recharge-tempo")
                        }}
                      >
                        RECHARGER
                      </button>
                    </div>
                  ) : null}
                </div>
              ) : null}
              {infoCard?.nextMeet?.name ? (
                <p className="account-card-info">
                  Votre prochain rendez-vous:
                  <br />
                  <span className="card-info-value">
                    {infoCard?.nextMeet?.name || "Massage Californien"}
                  </span>
                  <br />
                  <span className="card-info-value">
                    {moment(
                      infoCard?.nextMeet?.startDate || "21/08/2020",
                      "DD/MM/YYYY"
                    )
                      .locale("fr")
                      .format("DD MMMM YYYY")}{" "}
                    à{" "}
                    {moment(
                      infoCard?.nextMeet?.startDate || "21/08/2020",
                      "DD/MM/YYYY HH:mm"
                    )
                      .locale("fr")
                      .format("HH")}
                    H
                    {moment(
                      infoCard?.nextMeet?.startDate || "21/08/2020",
                      "DD/MM/YYYY HH:mm"
                    )
                      .locale("fr")
                      .format("mm")}
                  </span>
                </p>
              ) : null}

              <div className="nouv_zone">
                <p className="account-card-info">
                  Il vous reste{" "}
                  <span className="card-info-value">
                    {infoCard?.seances?.seancesLeft} séance
                    {infoCard?.seances?.seancesLeft > 1 ? "s" : null}
                  </span>
                  <br />
                  de Vitalité
                  {infoCard?.seances?.date ? (
                    <>
                      {" "}
                      jusqu’au{" "}
                      {moment(infoCard?.seances?.date)
                        .locale("fr")
                        .format("DD/MM/YYYY")}
                    </>
                  ) : null}
                </p>
                <div className="cont_new_btn">
                  {infoCard?.seances?.seancesLeft ? (
                    <button
                      type="button"
                      className="primary-caliceo--button "
                      onClick={() => {
                        const _data = {
                          session: cookies.get(
                            centerCookieIds[centerId]?.cookieName
                          ),
                          cid: centerCookieIds[centerId]?.cid,
                        }
                        const qstring = Object.keys(_data)
                          .map(key => `${key}=${_data[key]}`)
                          .join("&")
                        API.post(`/info-seances`, qs.stringify(_data), {
                          centerId,
                        }).then(res => {
                          if (res.data.status === 1) {
                            var offer_data = {}
                            offer_data.lid =
                              res.data.responseData.accountContent.seance.bcId
                            offer_data.bt = "B"
                            getBonCadeau(offer_data)
                          } else {
                            if (res.data.status > 1) {
                              if (
                                res.data.errorMessage != "" &&
                                res.data.errorMessage != undefined
                              ) {
                                notification.error({
                                  message: res.data.errorMessage,
                                })
                              }
                            }
                          }
                        })
                      }}
                    >
                      PLANIFIER
                      <br />
                      UNE SÉANCE
                    </button>
                  ) : null}
                  <button
                    type="button"
                    className="primary-caliceo--button type_2"
                    onClick={() => {
                      setShowCancellationModal2(true)
                    }}
                  >
                    ANNULER
                    <br />
                    UNE SÉANCE
                  </button>
                </div>
                {infoCard?.urlAlias?.vitalite ? (
                  <div className="cont_new_btn">
                    <button
                      type="button"
                      className="primary-caliceo--button "
                      onClick={() => {
                        //navigate("/produit" + infoCard.urlAlias.vitalite);
                        navigate("/offers/recharge-vitalite")
                      }}
                    >
                      RECHARGER
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <Collapse
            className="accordion_mes_produits"
            defaultActiveKey={["1", "2"]}
            expandIconPosition={"right"}
          >
            <Panel header="mes prochains rendez-vous" key="1">
              <div className="my-account-table-section">
                <div className={"loader " + (!load_1 ? "show" : "hide")}>
                  <img src={load_gif} style={{ width: 50 }} />
                </div>
                <div
                  className={"cont_cache anime " + (load_1 ? "show" : "hide")}
                >
                  <Table
                    className="active-products-table"
                    columns={activeRendezVousColumns}
                    rowKey={(record, index) => `${index}`}
                    loading={!userInfo?.products}
                    dataSource={rendezvousData}
                    pagination={{
                      position: ["none", "bottomCenter"],
                      pageSize: 6,
                    }}
                    showSorterTooltip={false}
                    locale={{ emptyText: "Aucun rendez-vous" }}
                  />
                </div>
              </div>
            </Panel>

            <Panel header="mes produits" key="2">
              <div className="my-account-table-section">
                <div className={"loader " + (!load_1 ? "show" : "hide")}>
                  <img src={load_gif} style={{ width: 50 }} />
                </div>
                <div
                  className={"cont_cache anime " + (load_1 ? "show" : "hide")}
                >
                  <Table
                    className="active-products-table"
                    columns={activeProductColumns}
                    rowKey={(record, index) => `${index}`}
                    loading={!userInfo?.products}
                    dataSource={sortedActiveProducts}
                    pagination={{
                      position: ["none", "bottomCenter"],
                      pageSize: 6,
                    }}
                    showSorterTooltip={false}
                    expandable={{
                      expandedRowRender: record => expandedRowRenderer(record),
                      onExpand,
                      rowExpandable: record =>
                        record?.abonnement?.productItems?.length > 0,
                      expandedRowKeys,
                      expandIcon: () => null,
                    }}
                    locale={{ emptyText: "Aucun produit" }}
                  />
                </div>
              </div>
            </Panel>
            <Panel header="Mes produits consommés/périmés" key="3">
              <div className="my-account-table-section">
                <div className={"loader " + (!load_1 ? "show" : "hide")}>
                  <img src={load_gif} style={{ width: 50 }} />
                </div>
                <div
                  className={"cont_cache anime " + (load_1 ? "show" : "hide")}
                >
                  <Table
                    className="active-products-table"
                    columns={activeProductColumns}
                    rowKey={(record, index) => `${index}`}
                    loading={!userInfo?.products}
                    dataSource={sortedExpiredProducts}
                    pagination={{
                      position: ["none", "bottomCenter"],
                      pageSize: 6,
                    }}
                    showSorterTooltip={false}
                    locale={{ emptyText: "Aucun produit" }}
                  />
                </div>
              </div>
            </Panel>
          </Collapse>
        </div>
      </div>
      {showBookingModal ? (
        <BalneoBooking
          bookingData={bookingData}
          showBookingModal={showBookingModal}
          setShowBookingModal={setShowBookingModal}
          setBookingData={setBookingData}
          centerId={centerId}
          setShowBookingCompleted={setShowBookingCompleted}
          pid={selectedOffer?.pid}
          bonCadeauData={bonCadeauData}
          choices={choices}
          setChoices={setChoices}
          setAbonid={setAbonid}
          abonid={abonid}
          setRs={setRs}
          rs={selectedOffer?.abonnement?.remaining}
          setSoid={setSoid}
          soid={selectedOffer?.abonnement?.id}
          selectedOffer={selectedOffer}
          sessionOverride={""}
          bcoid={null}
        />
      ) : null}
      {showSpaBookingModal ? (
        <SpaBookingModal
          spaBookingData={spaBookingData}
          setShowSpaCalendar={setShowSpaBookingModal}
          showSpaCalendar={showSpaBookingModal}
          offer={selectedOffer}
          quantity={null}
          variant={selectedOffer?.pid}
          centerId={centerId}
          isAccountPage
          setSpaBookingData={setSpaBookingData}
          selectedConsumable={selectedOffer}
          setShowBookingCompleted={setShowBookingCompleted}
          bonCadeauData={bonCadeauData}
          choices={choices}
          setChoices={setChoices}
          setAbonid={setAbonid}
          selectedOid={selectedOid}
          abonid={abonid}
          setRs={setRs}
          rs={selectedOffer?.abonnement?.remaining}
          setSoid={setSoid}
          soid={selectedOffer?.abonnement?.id}
          selectedOffer={selectedOffer}
          getFreshAccountInfo={actions.getFreshAccountInfo}
          setUserProducts={actions.setUserProducts}
          sessionOverride={""}
          bcoid={null}
        />
      ) : null}
      {showBookingCompleted ? (
        <BookingCompletedModal
          selectedVariant={selectedOffer?.oid}
          productType={selectedOffer?.bt === "B" ? "BALNEO" : "SPA"}
          setShowBookingCompleted={setShowBookingCompleted}
          showBookingCompleted={showBookingCompleted}
          isAccountPage
          centerId={centerId}
        />
      ) : null}
      {showFirstStepBooking ? (
        <SpaBookingFirstStep
          showFirstStepBooking={showFirstStepBooking}
          setShowFirstStepBooking={setShowFirstStepBooking}
          offer={bonCadeauData}
          setSelectedOid={setSelectedOid}
          selectedOid={selectedOid}
          selectedOffer={selectedOffer}
          productType={selectedOffer?.bt === "B" ? "BALNEO" : "SPA"}
          handleChangeVariant={handleChangeVariant}
          setGoToNextStepBooking={setGoToNextStepBooking}
          bonCadeauData={bonCadeauData}
          setBonCadeauData={setBonCadeauData}
          openRegularOffer={openRegularOffer}
          centerId={centerId}
          choices={choices}
          setChoices={setChoices}
          setAbonid={setAbonid}
          abonid={abonid}
          setRs={setRs}
          rs={selectedOffer?.abonnement?.remaining}
          setSoid={setSoid}
          soid={selectedOffer?.abonnement?.id}
          setShowLoader={setShowLoader}
          isOffert={false}
        />
      ) : null}
      {showCancellationModal ? (
        <CancellationModal
          centerId={centerId}
          showCancellationModal={showCancellationModal}
          setShowCancellationModal={setShowCancellationModal}
        />
      ) : null}
      {showCancellationModal2 ? (
        <CancellationModal2
          centerId={centerId}
          showCancellationModal2={showCancellationModal2}
          setShowCancellationModal2={setShowCancellationModal2}
          sessionOverride={""}
        />
      ) : null}
      {showLoader ? (
        <div className="load_account">
          <div className={"loader "}>
            <img src={load_gif} style={{ width: 50 }} />
          </div>
        </div>
      ) : null}
      {showNoBookingModal && (
        <NoBookingModal
          showNoBookingModal={showNoBookingModal}
          setShowNoBookingModal={setShowNoBookingModal}
        />
      )}
      {showRGPDModal && (
        <RGPDModal
          showRGPDModal={showRGPDModal}
          setShowRGPDModal={setShowRGPDModal}
        />
      )}
    </>
  )
}

export default MyAccount
